import React from 'react';
import { Bar } from 'react-chartjs-2';
import TempInfo from './subViews/tempInfo';
import Cookies from 'universal-cookie';


export default class Temperature extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: {},
            redraw: false,
            token: ""
        }
    }

    componentDidMount() {
        const cookies = new Cookies();
        let token = cookies.get('token');
        console.log(token)
        if(token){
            this.getInternalTemp(token)
            this.setState({token});
        }
       //this.getTemperatureData()
    }


    getInternalTemp = (token) => {
        
        fetch("/api/temperature", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': "Bearer " +  token
            },
            })
            .then(
                (response) =>  {
                    if(response.ok) {
                        response.json().then(data => {
                            console.log(data) 
                            let max =  Math.max.apply(Math, data.map(function(o) { return o.id_temp; }))
                            var obj = data.find(function(o){ return o.id_temp == max; })
                            this.setState({data:obj})
                            console.log(obj) 
                            //this.setArray(data)                    
                        });
                    } else {
                    response.json().then(data => {
                        console.log(data)                          
                    });
                    }
                }
            )
        
    }

    getTemperatureData = () => {

        

        fetch("https://api.thingspeak.com/channels/176756/feeds.json?api_key=V94V7QIF06F726OX&results=200", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
                //'Authorization': "Bearer " +  this.state.token
            },
            })
            .then(
                (response) =>  {
                    if(response.ok) {
                        response.json().then(data => {
                            console.log(data) 
                            this.setArray(data)                    
                        });
                    } else {
                    response.json().then(data => {
                        console.log(data)                          
                    });
                    }
                }
            )
        
    }

    setArray = (data) => {   
        
        let feed = data.feeds;
        let array_to_set = [];
        let labels_to_set = [];

        feed.forEach(element => {
            console.log(element.field1)
            array_to_set.push(element.field1);
            labels_to_set.push(element.created_at);
            
        });

        let array_to_store = {
            labels: labels_to_set,
            datasets: [
              {
                label: 'Temperatures',
                fill: true,
                lineTension: 0.2,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(120,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: array_to_set
              }
            ]
          }

        this.setState({data:array_to_store, redraw:true})
        //<Bar data={this.state.data} redraw={this.state.redraw}/>   TO PUT ON RENDER IF NEDEED

    }
    
    render = () => {
        return(
            <div className="container text-center">
                <TempInfo temperature={this.state.data.temperature} device_id={this.state.data.device_id} device_name={this.state.data.device_name} date={this.state.data.date}/>
                <TempInfo temperature={this.state.data.temperature} device_id={this.state.data.device_id} device_name={this.state.data.device_name} date={this.state.data.date}/>
              
              
        </div>
                )
    }
}