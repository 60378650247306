import React from 'react';
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";


export default class MessageHandler extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = { 
            open: false,
            message: "",
            variant: "error"
        };

        
       
      }

    handleClose = () => {
        this.setState({open:false})

    }
    componentDidUpdate = (prevProps) => {
        console.log( prevProps )
        console.log( " props ")  
        console.log(this.props)
        if (prevProps.open !== this.props.open) { 
            this.setState({open:this.props.open, message: this.props.message, variant:this.props.variant})
        }
      }
    
    
    render = () => {

        const variantIcon = {
            success: CheckCircleIcon,
            warning: WarningIcon,
            error: ErrorIcon,
            info: InfoIcon
          };

        const useStyles1 = makeStyles(theme => ({
            success: {
              backgroundColor: green[600]
            },
            error: {
              backgroundColor: theme.palette.error.dark
            },
            info: {
              backgroundColor: theme.palette.primary.main
            },
            warning: {
              backgroundColor: amber[700]
            },
            icon: {
              fontSize: 20
            },
            iconVariant: {
              opacity: 0.9,
              marginRight: theme.spacing(1)
            },
            message: {
              display: "flex",
              alignItems: "center"
            }
          }));

          function MySnackbarContentWrapper(props) {
            const classes = useStyles1();
            const { className, message, onClose, variant, ...other } = props;
            const Icon = variantIcon[variant];
          
            return (
              <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                  >
                    <CloseIcon className={classes.icon} />
                  </IconButton>
                ]}
                {...other}
              />
            );
          }

        return(
            <div className="container text-center">
               <Snackbar
                    anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    
                >
                    <MySnackbarContentWrapper
                    onClose={this.handleClose}
                    variant={this.state.variant}
                    message={this.state.message}
                    />
                </Snackbar>
        </div>
                )
    }
}