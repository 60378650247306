import React from 'react';
import MainPage from './mainPage';
import Login from './login';
import Cookies from 'universal-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useLocation,
    useHistory
  } from "react-router-dom";
import Settings from './settings';
import Temperature from './temperature'
import Power from './powerChart'

export default class Wrapper extends React.Component {
    constructor(props){
        super(props);
            this.state = {
                token: "",
                settingShow: false,
                isLoged: false
            }
        }
        componentWillMount(){
            const cookies = new Cookies();
            let token = cookies.get('token');
            //console.log(token);
            if(token){
                this.setState({token,isLoged:true});
            }
        
        }

    componentDidMount(){
        const cookies = new Cookies();
        let token = cookies.get('token');
        //console.log(token);
        if(token){
            this.setState({token,isLoged:true});
        }
        
        
        
    }

    componentDidUpdate(){
        const cookies = new Cookies();
        let token = cookies.get('token');
        if(token && !this.state.isLoged){
            this.setState({token,isLoged:true});
        }
    }


    
    render = () => {
        let isAuthenticated = this.state.isLoged;

        function PrivateRoute({ children, ...rest }) {
            
            return (
              <Route
                {...rest}
                render={({ location }) =>
                  isAuthenticated ? (
                    children
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: location }
                      }}
                    />
                  )
                }
              />
            );
          }
       
        return (
            <div >     
                
                <Router>
                    
                            <Navbar collapseOnSelect  expand="lg"  bg="dark" variant="dark">
                            <Navbar.Brand eventKey="1" as={Link} to="/">Home App</Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse>
                                    <Nav className="mr-auto ">
                                    <Nav.Item>
                                        <Nav.Link eventKey="1" as={Link} to="/">
                                        Home
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="2" as={Link} to="/temperature">
                                        Temperature
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="3" as={Link} to="/settings">
                                        Settings
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="4" as={Link} to="/power">
                                        Power
                                        </Nav.Link>
                                    </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        
                            <br></br>
                     
                        <Switch>
                            <PrivateRoute exact path="/">
                                <MainPage />
                                
                            </PrivateRoute>
                            <PrivateRoute path="/temperature">
                                <Temperature />
                                
                            </PrivateRoute>
                            <PrivateRoute path="/settings">
                                <Settings />
                                
                            </PrivateRoute>
                            <PrivateRoute path="/power">
                                <Power />
                                
                            </PrivateRoute>
                            <Route path="/login">
                                <Login/>
                                
                            </Route>
                            </Switch>
                           
                </Router>
                
                
                </div>

        )
      }
}