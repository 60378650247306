import React from 'react';
import Button from '@material-ui/core/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageHandler from './subViews/messageHandler';
import Cookies from 'universal-cookie';
import { LineChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, Legend,
  Line, ReferenceLine, ReferenceDot,
    LabelList, Label } from 'recharts';
import solarPanel from '../img/solar-house.png'; 
import customCss from '../App.css';


export default class MainPage extends React.Component {

  state = {
    productionByDay : [],
    lastProduction : [],
  }

  componentDidMount(){
    const cookies = new Cookies();
    let token = cookies.get('token');
    
    if(token){
      this.getDataFromAPIByDay(token);
      this.getDataFromAPILast(token);
    }
    
    
  }

  getDataFromAPIByDay(token){
       
    fetch("/api/power/day", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': "Bearer " +  token
      },
      })
      .then(
          (response) =>  {
              if(response.ok) {
                  response.json().then(data => {
                      console.log(data) 
                      this.setState({productionByDay:data})
                      //this.setArray(data)                    
                  });
              } else {
              response.json().then(data => {
                  console.log(data)                          
              });
              }
          }
      )
  }

  getDataFromAPILast(token){
       
    fetch("/api/power/last", {
      method: "GET",
      headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': "Bearer " +  token
      },
      })
      .then(
          (response) =>  {
              if(response.ok) {
                  response.json().then(data => {
                      console.log(data) 
                      this.setState({lastProduction:data[0]})
                      //this.setArray(data)                    
                  });
              } else {
              response.json().then(data => {
                  console.log(data)                          
              });
              }
          }
      )
  }

  


  render(){
    const data = this.state.productionByDay ;
    const current_data = this.state.lastProduction ;

    return (
      
      <div className="container text-center">

     <h2> Dados Produção corrente </h2>
        <br/>
        <img src={solarPanel}alt="Solar Panel"  width="200" height="200"/>
        <br/>
        <h3>A produzir </h3>
        <h1> {current_data.w} Watts</h1>

        <br/>
        <br/>
        <hr/>
      <h2> Produção Por Dia </h2>
 
        <LineChart
        width={350}
        height={300}
        data={data}
        margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
        >
        <XAxis dataKey="refers_to_date" />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" />
        <Line type="monotone" dataKey="kwh" stroke="#ff7300" yAxisId={0} />
        </LineChart>

      </div>
    )
  };


}