import React from 'react';

export default class TempInfo extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            temperature: "00",
            device: "ID0",
            device_name :"DEVICE00",
            date:"2000-01-02T11:00:00"
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.temperature !== prevProps.temperature) {
          this.setState({temperature:this.props.temperature, device:this.props.device_id ,device_name:this.props.device_name , date:this.props.date });

        }
      }

    
    render = () => {
        return(

            <div class="align-self-center">
                <div class="col-lg-6">
                    <div class="card"> <img class="" src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1559286899/weatherbg.jpg" alt="Card cap"/>
                        <div class="card-img-overlay" >
                            <h3 class="card-title text-white m-b-0 dl">{this.state.device_name}</h3> <small class="card-text text-white font-light">{this.state.date}</small>
                        </div>
                        <div class="card-body weather-small">
                            <div class="row">
                                <div class="col-8 b-r align-self-center">
                                    <div class="d-flex">
                                        <div class="text-info"><i class="mdi mdi-weather-pouring"></i></div>
                                        <div class="m-l-20">
                                            <h1 class="font-light text-info m-b-0">{this.state.temperature}<sup>0</sup></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}