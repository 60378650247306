import React from 'react';
import TextField from '@material-ui/core/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '@material-ui/core/Button';
import MessageHandler from './subViews/messageHandler';
import Cookies from 'universal-cookie';


export default class Settings extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: "",
            password:"",
            email: "",
            open:false,
            variant:"error",
            message:""

        }
    }


    login = () => {
        
        fetch("/api/auth/register", {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body:'{"name":"'+ this.state.username +'", "password":"'+ this.state.password + '" , "email": "'+ this.state.email +'"}'
          })
          .then((response) => {
            if(response.ok) {
              
                response.json().then(data => {
                    console.log(data)
                    this.handleSucess();
                   

                });
                } else {
                    response.json().then(data => { 
          
                      console.log(data);
                      this.handleError(data.message);
                      
                    });
                    
                  }
                }).catch((error)=> {
                  
                    this.handleError(error.message);

                });

    }

    handleError = (error) => {
        this.setState({open:true, variant:"error",message:"Error: " + error});
        this.timer(3000) 
      }

      handleKey = (e) => {
        if (e.key === 'Enter') {
            this.login();
        }
      }

      
    timer = (time) => {
        setTimeout(function() { 
        this.setState({open: false}) 
        }.bind(this), time)
    }

      handleSucess = () => {
        this.setState({open:true, variant:"success",message:"Register Ok"});
        this.timer(3000) 
      }

      storeCookies = (cookieName, data) => {
        const cookies = new Cookies();
        cookies.set(cookieName,data, {maxAge:86400})
      }
    
    
    render = () => {
        return(
            <div className="container text-center">
                Register user
            <form  autoComplete="off" onSubmit={this.login}>
                    <TextField
                        id="outlined-username-input"
                        label="Username"
                        type="name"
                        name="username"
                        margin="normal"
                        variant="outlined"
                        value={this.state.username}
                        onChange={e => this.setState({ username: e.target.value })}
                    />
                    <br/>
                    <TextField
                        id="outlined-username-input"
                        label="Email"
                        type="email"
                        name="username"
                        margin="normal"
                        variant="outlined"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <br/>
                    <TextField
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                        onKeyDown={this.handleKey}
                    />
                    <br/>
                
            </form>
            <Button variant="contained" color="primary" onClick={this.login}>
                Save
            </Button>


            <MessageHandler open={this.state.open} message={this.state.message} variant={this.state.variant}/>
        </div>
                )
    }
}