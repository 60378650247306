import React from 'react';
import './App.css';
import Wrapper from './views/wrapper';

function App() {
  return (
   <Wrapper></Wrapper>
   
  );
}

export default App;
