import React from 'react';
import Button from '@material-ui/core/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageHandler from './subViews/messageHandler';
import Cookies from 'universal-cookie';


export default class MainPage extends React.Component {

  state = {
    token: "",
    open: false,
    variant: "success",
    message: "",
  }

  componentDidMount(){
    const cookies = new Cookies();
    let token = cookies.get('token');
    
    if(token){
        this.setState({token});
    }

    
  }


  openGate = () => {
     

               fetch("/api/action/open", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': "Bearer " +  this.state.token
                },
                })
                .then(
                    (response) =>  {
                        if(response.ok) {
                          this.setState({open:true, variant:"success",message:"Opening"});
                          this.timer(3000);
                        } else {
                        response.json().then(data => {
                            this.handleError(data)                           
                        });
                        }
                    }
                )
                 
    
  }


  timer = (time) => {
    setTimeout(function() { 
      this.setState({open: false}) 
      }.bind(this), time)
  }


  handleError = (error) => {
    this.setState({open:true, variant:"error",message:"Error: " + error});
    this.timer(3000) 
  }



  render(){
    return (

      <div className="container text-center">

      
        
       <Button variant="contained" color="primary" onClick={this.openGate} size="large" fullWidth="true" >
            Open Gate
        </Button>

        <MessageHandler open={this.state.open} message={this.state.message} variant={this.state.variant}/>
      </div>
    )
  }

}